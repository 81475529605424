<template>
    <div class="dashboard">
        <div class="row mb-4">
            <div class="col-xl-3 col-md-6">
              <div class="card card-animate">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1 overflow-hidden">
                      <p class="text-uppercase fw-medium text-muted text-truncate mb-0">
                        Coverages
                      </p>
                    </div>
                  </div>
                  <div class="d-flex align-items-end justify-content-between mt-4">
                    <div>
                      <h5 class="fs-22 fw-semibold ff-secondary mb-4">
                        {{result.coverages}}
                      </h5>
                      <router-link to="/coverages" class="small text-decoration-underline"
                        >View coverages</router-link>
                    </div>
                    <div class="avatar-sm flex-shrink-0">
                      <span class="avatar-title bg-soft-primary rounded fs-3">
                        <i class="bx bx-wallet text-primary"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-6">
              <div class="card card-animate">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1 overflow-hidden">
                      <p class="text-uppercase fw-medium text-muted text-truncate mb-0">
                        Customers
                      </p>
                    </div>
                  </div>
                  <div class="d-flex align-items-end justify-content-between mt-4" >
                    <div>
                      <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                        {{result.users.customer}}
                      </h4>
                      <router-link to="/users?role=customer" class="small text-decoration-underline" >See details</router-link>
                    </div>
                    <div class="avatar-sm flex-shrink-0">
                      <span class="avatar-title bg-soft-warning rounded fs-3">
                        <i class="bx bx-user-circle text-warning"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-6">
              <div class="card card-animate">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1 overflow-hidden">
                      <p  class="text-uppercase fw-medium text-muted text-truncate mb-0" >
                        Partners
                      </p>
                    </div>
                  </div>
                  <div class="d-flex align-items-end justify-content-between mt-4" >
                    <div>
                      <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                        {{result.users.partner}}
                      </h4>
                      <router-link to="/users?role=partner" class="small text-decoration-underline"
                        >See details</router-link>
                    </div>
                    <div class="avatar-sm flex-shrink-0">
                      <span class="avatar-title bg-soft-info rounded fs-3">
                        <i class="ri ri-team-line text-info"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-6">
              <div class="card card-animate">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1 overflow-hidden">
                      <p class="text-uppercase fw-medium text-muted text-truncate mb-0" >
                        Subscriptions
                      </p>
                    </div>
                  </div>
                  <div class="d-flex align-items-end justify-content-between mt-4">
                    <div>
                      <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                        {{result.subscriptions.total}}
                      </h4>
                      <router-link to="/subscriptions" class="text-decoration-underline" >See details</router-link>
                    </div>
                    <div class="avatar-sm flex-shrink-0">
                      <span class="avatar-title bg-soft-success rounded fs-3">
                        <i class="bx bx-dollar-circle text-success"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-12">
                <div class="row gy-3">
                    <div class="col-md-12">
                        <h5 class="text-primary">Recent Orders</h5>

                        <div class="card">
                            <div class="bg-holder bg-card" style="background-image:url(assets/img/icons/spot-illustrations/corner-1.png);"></div>
                            <div class="card-body position-relative">
                           
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>

export default {
    name: 'dashboard',
    data(){
        return{
            result:{
              users: {
                partner: 0,
                customer: 0,
              },
              subscriptions: {
                total: 0
              },
              coverages: 0
            }
        }
    },
    computed:{
        activeUser(){
            return this.$store.state.AppActiveUser
        },
    },
    created(){
        this.$http.get('/analytics')
        .then((response) => {
            if(response.data.success){
                this.result = response.data.data;
            }
        })
    }
}
</script>

<style scoped>
.user-photo{
    width:9rem;
}
@media(max-width:567px){
    .user-photo{
        width:4rem;
    }
}
.dashboard .table.profile-table td, .dashboard .table.profile-table th{
    padding:0.1rem 0.2rem;
}

</style>
